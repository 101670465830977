<template>
  <div>
    <CRow>
      <CCol>
        <config-setting-calendar @update="onSearch"/>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
<!--        {{objCalendars}}-->
        <table-list
          :caption="this.onCheck(this.fields.calendars)"
          :items="this.data.calendars" :fields="this.onCheck(this.table.calendars)"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>

  import {mapGetters} from 'vuex'
  import ConfigSettingCalendar from "@/project/containers/ConfigSettingCalendar";
  import TableList from "@/project/containers/sub/TableList";
export default {
  name: 'Calemdar',
  components: {TableList, ConfigSettingCalendar},
  data: function () {
    return {
      lang: "th",
      table: {
        calendars: [
          {
            key: "th",
            value: [
              {key: 'no', label: 'ลำดับ'},
              {key: 'academicYear', label: 'ปีการศึกษา'},
              {key: 'semester', label: 'ภาคการศึกษา'},
              {key: 'startDate', label: 'เริ่มต้น'},
              {key: 'endDate', label: 'สิ้นสุด'},
              {key: 'schoolName', label: 'สถาบัน'},
              {key: 'facultys', label: 'สำนักวิชา'},
              {key: 'department', label: 'สาขาวิชา'},
              {key: '#', label: '#'},
            ]
          },
          {
            key: "en",
            value: [
              {key: 'no', label: 'no'},
              {key: 'academicYear', label: 'academicYear'},
              {key: 'semester', label: 'semester'},
              {key: 'startDate', label: 'startDate'},
              {key: 'endDate', label: 'status'},
              {key: 'schoolName', label: 'schoolName'},
              {key: 'facultys', label: 'facultys'},
              {key: 'department', label: 'department'},
              {key: '#', label: '#'},
            ]
          }
        ]
      },
      fields: {
        calendars : [
          {
            key: "th",
            value: "ปฏิทิน"
          },
          {
            key: "en",
            value: "Calendars"
          }]
      },
      data : {
        calendars :[]
      }
    }
  },
  mounted () {

  },

  created () {
    this.$store.dispatch("calendars/onGetCalendars")
  },

  beforeDestroy () {

  },

  methods: {
    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    onSearch(value){
      this.$store.dispatch("calendars/onCreateCalendars", value)
    },




  },

  computed:{
    ...mapGetters({
      objCalendars : "calendars/objCalendars"
    })
  },

  watch: {
    objCalendars:function (value) {
      var item = [];
      for(var i=0; i<value.length; i++){
        var obj = {};
        obj.academicYear = value[i].academicYear;
        obj.semester = value[i].semester;
        obj.startDate = value[i].startDate;
        obj.endDate = value[i].endDate;
        obj.facultys = this.onCheck(value[i].facultys.name);
        obj.department = this.onCheck(value[i].department.name);
        obj.schoolName = this.onCheck(value[i].schoolName.name);
        obj.obj =  value[i]

        item.push(obj);
      }
      this.data.calendars =  item;


    }
  }
}
</script>
