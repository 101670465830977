<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader color="primary">
            <CRow>
              <CCol class=" text-white ">
                <CIcon name="cil-calendar"/>
                <span class="font-weight-bold ml-1">{{ onCheck(fields.title) }}</span>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CForm>

              <CSelect :label="onCheck(fields.schoolName)"
                       :disabled="disable"
                       :horizontal="horizontals"
                       :options="onCheck(campus)"
                       @update:value="onSelectSchoolName"
              />
              <CSelect
                  :label="onCheck(fields.department)"
                  :horizontal="horizontals"
                  :options="onCheck(facultys)"
                  @update:value="onSelectDepartment"

              />
              <CSelect
                  :label="onCheck(fields.program)"
                  :horizontal="horizontals"
                  :options="onCheck(departments)"
                  @update:value="onSelectprogram"
              />

              <CRow>
                <CCol sm="6" col="12">
                  <CInput type="text" :horizontal="horizontal"
                          :label="onCheck(fields.academicYear)"
                          v-model="data.academicYear"/>
                </CCol>
                <CCol sm="6" col="12">

                  <CInput type="text" :horizontal="horizontal"
                          :label="onCheck(fields.semester)"
                          v-model="data.semester"/>

                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CInput
                      :label="onCheck(fields.startDate)"
                      type="date"
                      :horizontal="horizontal"
                      v-model="data.startDate"
                  />
                </CCol>
                <CCol>
                  <CInput
                      :label="onCheck(fields.endDate)"
                      type="date"
                      :horizontal="horizontal"
                      v-model="data.endDate"
                  />
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <CCardFooter>
            <div class="float-right">
              <CButton class="mr-2" color="danger"><CIcon name="cil-ban"/> {{onCheck(fields.cancel)}}</CButton>
              <CButton type="submit" color="success" @click="onCheckValue"><CIcon name="cil-check-circle"/> {{onCheck(fields.submit)}}</CButton>
            </div>
          </CCardFooter>
        </CCard>


      </CCol>
    </CRow>
  </div>
</template>

<script>

const today = new Date()
import {DatePicker} from 'v-calendar'
import {mapGetters} from 'vuex'

export default {
  name: 'ConfigSettingCalendar',
  components: {
    'v-date-picker': DatePicker
  },
  props: {
    disable: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      horizontals: {label: 'col-sm-2 col-4', input: 'col-sm-10 col-8'},
      horizontal: {label: 'col-4', input: 'col-8'},
      lang: "th",

      selectedDate: {
        start: new Date(today.getFullYear(), today.getMonth(), 7),
        end: new Date(today.getFullYear(), today.getMonth(), 11)
      },

      fields: {
        title: [
          {
            key: "th",
            value: "ตั้งค่าปฏิทินการปฏิบัติงาน TQF"
          },
          {
            key: "en",
            value: "Set up an operating calendar TQF"
          }
        ],
        submit : [
          {
            key: "th",
            value: "ยืนยัน"
          },
          {
            key: "en",
            value: "submit"
          }
        ],
        cancel : [
          {
            key: "th",
            value: "ยกเลิก",
          },
          {
            key: "en",
            value: "cancel"
          }
        ],
        schoolName: [
          {
            key: "th",
            value: "ชื่อสถาบันอุดมศึกษา"
          },
          {
            key: "en",
            value: "Name of University"
          }
        ],
        department: [
          {
            key: "th",
            value: "สำนักวิชา"
          },
          {
            key: "en",
            value: "School"
          }
        ],
        program: [
          {
            key: "th",
            value: "สาขาวิชา"
          },
          {
            key: "en",
            value: "Program"
          }
        ],
        academicYear: [
          {
            key: "th",
            value: "ปีการศึกษา"
          },
          {
            key: "en",
            value: "Academic Year"
          }
        ],
        semester: [
          {
            key: "th",
            value: "ภาคการศึกษา"
          },
          {
            key: "en",
            value: "semester"
          }
        ],
        startDate: [
          {
            key: "th",
            value: "เริ่มต้น"
          },
          {
            key: "en",
            value: "startDate"
          }
        ],
        endDate: [
          {
            key: "th",
            value: "สิ้นสุด"
          },
          {
            key: "en",
            value: "endDate"
          }
        ],
      },

      data:{
        schoolName:null,
        facultys:{},
        department:{},
        academicYear: null,
        semester: null,
        startDate: null,
        endDate: null,
      }
    }
  },
  mounted() {

  },

  created() {
    this.onInit();
  },

  beforeDestroy() {

  },

  methods: {
    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    onInit() {

      this.$store.dispatch("course/onGetCampus")
      this.$store.dispatch("course/onGetFacultys")
      this.$store.dispatch("course/onGetCourses")
      this.$store.dispatch("course/onGetOperations")
      this.$store.dispatch("course/onGetVerifications")

    },
    onSelectSchoolName(value, e) {
      console.log(value)

    },
    onSelectDepartment(value, e) {
      this.$store.dispatch("course/onGetDepartment", value.code)

    },
    onSelectprogram(value, e) {
      this.department = value
    },

    onCheckValue(){
      this.$emit("update",this.data)
    }

  },

  computed: {
    ...mapGetters({
      campus: "course/objCampus",
      facultys: "course/objFacultys",
      departments: "course/objDepartments",
    })
  },

  watch: {
    campus(value){
      this.data.schoolName = this.onCheck(value)[0].value
    },

    facultys(value){
      this.data.facultys = this.onCheck(value)[0].value
    },

    departments(value){
      this.data.department = this.onCheck(value)[0].value
    }
  }
}
</script>
